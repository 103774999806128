import React, { useState, useEffect } from 'react';
import { Table, Modal } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';

export default function ProprietariosModal({ showProprietarios, proprietarios, handleClickEdit, setShowProprietarios }) {

  /*
    Paginação
  */
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 5;

  useEffect(() => {
    if (showProprietarios) {
      setCurrentItems([]);
      setPageCount(0);
      setItemOffset(0);
    }
  }, [showProprietarios]);  

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(proprietarios.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(proprietarios.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, proprietarios]);

  const handlePageClick = (event) => {
    const newOffset = event.selected * itemsPerPage % proprietarios.length;
    setItemOffset(newOffset);
  };

  return (

    <Modal
      show={showProprietarios}
      onHide={() => setShowProprietarios(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Proprietários</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th>Nome</th>
              <th>Propriedade</th>
            </tr>
          </thead>
          <tbody>
            {currentItems.map((proprietario) => (
              <tr key={proprietario.codigo} onClick={() => handleClickEdit(proprietario.codigo)} style={{ cursor: "pointer" }}>
                <td>{proprietario.nome}</td>
                <td>{proprietario.propriedade}</td>
              </tr>
            ))}
          </tbody>
        </Table>

        <ReactPaginate
          nextLabel=">"
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          marginPagesDisplayed={2}
          pageCount={pageCount}
          previousLabel="<"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          containerClassName="pagination"
          activeClassName="active"
          renderOnZeroPageCount={null}
        />
      </Modal.Body>
    </Modal>
  );
}

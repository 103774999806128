import React, { useState, useEffect } from 'react';
import { Table, Modal } from 'react-bootstrap';
import { formatDate } from '../components/utils';
import ReactPaginate from 'react-paginate';

export default function SociosModal({ showSocios, socios, handleClickEdit, setShowSocios }) {

  /*
    Paginação
  */
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 5;

  useEffect(() => {
    if (showSocios) {
      setCurrentItems([]);
      setPageCount(0);
      setItemOffset(0);
    }
  }, [showSocios]);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(socios.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(socios.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, socios]);

  const handlePageClick = (event) => {
    const newOffset = event.selected * itemsPerPage % socios.length;
    setItemOffset(newOffset);
  };


  return (
    <Modal show={showSocios} onHide={() => setShowSocios(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Sócios</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th>Matrícula</th>
              <th>Nome</th>
              <th>Nascimento</th>
            </tr>
          </thead>
          <tbody>
            {currentItems.map((socio) => (
              <tr key={socio.matricula} onClick={() => handleClickEdit(socio.matricula)} style={{ cursor: "pointer" }}>
                <td>{socio.matricula}</td>
                <td>{socio.socio_nome}</td>
                <td>{formatDate(socio.socio_nascimento)}</td>
              </tr>
            ))}
          </tbody>
        </Table>
        <ReactPaginate
          nextLabel=">"
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          marginPagesDisplayed={2}
          pageCount={pageCount}
          previousLabel="<"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          containerClassName="pagination"
          activeClassName="active"
          renderOnZeroPageCount={null}
        />
      </Modal.Body>
    </Modal>
  );
}

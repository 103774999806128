import React, { useState, useRef, useEffect } from 'react';
import { Col, Row, Form, InputGroup, Button } from 'react-bootstrap';
import { FaSearch, FaPlus, FaEye, FaEyeSlash } from 'react-icons/fa';
import AlertModal from '../components/AlertModal';
import LoadingSpinner from '../components/LoadingSpinner';
import apiService from '../services/apiService';
import UserModal from './UserModal';
import { useNavigate } from 'react-router-dom';

//import {formatDate, parseDate} from '../components/utils';

export default function UserForm() {

  // Estado inical para os campos do formulário.
  const initialStateUser = {
    id: '',
    code: '',
    name: '',
    username: '',
    is_admin: 0,
    password: '',
    passwordconfirm: ''
  };

  const [formUser, setformUser] = useState(initialStateUser);

  // Estado inicial para ocultar/mostrar. 
  const [showUsers, setShowUsers] = useState(false);
  const [titleAlert, setTitleAlert] = useState('');
  const [messageAlert, setMessageAlert] = useState('');
  const [typeAlert, setTypeAlert] = useState('alert');
  const [showAlert, setShowAlert] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showPassField, setShowPassField] = useState(true); // mostrar ou ocultar os campos de senha
  const navigate = useNavigate();

  // Armazenar os users da pesquisa.
  const [users, setUsers] = useState([]);

  const userNameRef = useRef(null);
  const idRef = useRef(null);
  const passwordRef = useRef(null);

  /*
    ocultar os campos de senha e mostrar botão de mudar senha
    se for fazer uma alteração
  */
  useEffect(() => {
    setShowPassField(!formUser.id);
  }, [formUser.id]);


  /*
    colocar foco no password somente quando
    clicar no botão de alterar a senha
  */
  useEffect(() => {
    if (passwordRef.current && formUser.id !== "") {
      passwordRef.current.focus();
    } else if (formUser.id !== "") {
      userNameRef.current.focus();
    }
  }, [showPassField,formUser.id]);


  /*
    Para usar o enter como tab no input de código
  */
  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      const nextElement = document.querySelector('[tabIndex="0"]');
      nextElement.focus();
    }
  };

  /*
    Limpa o formulário e coloca
    o cursor no input nome.
  */
  const newUser = () => {
    setformUser(initialStateUser);
    setShowUsers(false);
    idRef.current.focus(); // colocar o cursor no campo nome
  };

  const handleClickNewUser = () => {
    newUser();
  }

  /*
    Função para validar os campos do formulário
    no handleSubmitUser menos as senhas
  */
  const validFields = () => {
    if (
      parseInt(formUser.code) === 0 ||
      formUser.name.trim() === '' ||
      formUser.username.trim() === ''
      ) {
      setTypeAlert('alert');
      setTitleAlert('Campos inválidos!');
      setMessageAlert('Preencha todos os campos.');
      setShowAlert(true);
      userNameRef.current.focus();
      return false;
    } else {
      return true;
    }
  }


  /*
    Função para validar as senhas
  */
    const validPassword = () => {
      if (formUser.password !== formUser.passwordconfirm || formUser.password === "") {
        setTypeAlert('alert');
        setTitleAlert('Senhas Diferentes!');
        setMessageAlert('Digite a senha e a confirmação da senha novamente.');
        setShowAlert(true);
        passwordRef.current.focus();
        return false;
      } else {
        return true;
      }
    }


  /*
    Atualiza os dados dos formulários
    quando houver alguma alteração.
  */
  const handleChangeUser = (event) => {
    setShowAlert(false);
    const { name, value } = event.target;
    setformUser((prevformUser) => ({
      ...prevformUser,
      [name]: value,
    }));
  };

  /*
    Atualiza o valor para o botão administrador
  */
  const handleSwitchChange = (event) => {
    setformUser((prevformUser) => ({
      ...prevformUser,
      is_admin: event.target.checked
    }));
  };

  /*
    Mostar o resultado da pesquisa
    por sócio na tabela
  */
  const handleClickSearch = (event) => {
    event.preventDefault(); // Evitar o recarregamento da página.

    setShowUsers(false);
    setShowAlert(false);
    setShowLoading(true);

    if (formUser.name.trim() !== '') {
      apiService
        .getUserByName(formUser.name)
        .then((result) => {
          if (result.status === 200) {
            setUsers(result.data);
            setShowUsers(true);
            setformUser(initialStateUser);
          }
        })
        .catch((error) => {
          setTypeAlert('alert');
          setTitleAlert('Atenção!');
          //se houver erro de autenticação direcionar para o controller LoginComponent
          if (error.response.status === 410 || error.response.status === 401) {
            navigate('/login');
            return;
          } else if (error.response.status === 404) {
            setMessageAlert('Nenhum usuário foi encontrado.');
            setShowAlert(true);
          } else {
            setMessageAlert(error.message);
            setShowAlert(true);
          }
        })
        .finally(() => {
          userNameRef.current.focus();
          setShowLoading(false);
        });
    } else {
      // se o campo estiver vazio retornar todos usuários
      apiService
        .getAllUser()
        .then((result) => {
          if (result.status === 200) {
            setUsers(result.data);
            setShowUsers(true);
            setformUser(initialStateUser);
          }
        })
        .catch((error) => {
          setTypeAlert('alert');
          setTitleAlert('Atenção!');
          //se houver erro de autenticação direcionar para o controller LoginComponent
          if (error.response.status === 410 || error.response.status === 401) {
            navigate('/login');
            return;
          } else if (error.response.status === 404) {
            setMessageAlert('Nenhum usuário foi encontrado.');
            setShowAlert(true);
          } else {
            setMessageAlert(error.message);
            setShowAlert(true);
          }
        })
        .finally(() => {
          userNameRef.current.focus();
          setShowLoading(false);
        });
    }
  };

  /*
    Atualização dos dados do formulário vindo através do
    do botão edit da tabela de pesquisa ou quando o campo
    código perder o foco.
  */
  const handleClickEdit = (code) => {
    
    // Condição usada para quando o campo codigo perder o foco
    if (code) {
      setShowUsers(false);
      setShowLoading(true);

      apiService
        .getUserByCode(code)
        .then((result) => {
          
          if (result.status === 200) {
            const { id, code, name, is_admin, username} = result.data;

            /* se o result.data retornar algum campo com valor nulo
               substituir por '' para evitar erro no código
            */
            setformUser({
              id: id ?? '',
              code: code ?? '',
              name: name ?? '',
              username: username ?? '',
              is_admin: is_admin ?? ''
            });

            userNameRef.current.focus();
          }
        })
        .catch((error) => {
          //se houver erro de autenticação direcionar para o controller LoginComponent
          if (error.response.status === 410 || error.response.status === 401) {
            navigate('/login');
            return;
          } else if (error.response.status === 404) {
              userNameRef.current.focus();
          } else {
            setTypeAlert('alert');
            setTitleAlert('Atenção!');
            setMessageAlert(error.message);
            setShowAlert(true);
          }
        })
        .finally(() => {
          setShowLoading(false);
        });
    }
  };

  /*
    Enviar os dados do formulário para a api após o submit.
    Se o formUser.id estiver vazio será enviado para cadastro,
    senão, será feita uma atualização dos dados. 
  */
  const handleSubmitUser = (event) => {
    event.preventDefault(); // evitar o recarregamento da página

    if (validFields()) {

      if (formUser.password !== formUser.passwordconfirm) {
        setTypeAlert('alert');
        setTitleAlert('Atenção!');
        setMessageAlert('As senhas não são iguais. Digite novamente.');
        setShowAlert(true);
        passwordRef.current.focus();
        setformUser((prevformUser) => ({
          ...prevformUser,
          password: '',
          passwordconfirm: ''
        }));
        return;
      }

      setShowLoading(true);

      // se não houver id então é uma inclusão de novo usuário
      if (formUser.id === '' && validPassword()) {

        const updatedformUser = { ...formUser };
       
        setformUser(updatedformUser);

        apiService
          .newUser(formUser)
          .then((result) => {
            
            if (result.status === 200) {
              setTypeAlert('alert');
              setTitleAlert('Sucesso!');
              setMessageAlert('Usuário cadastrado.');
              setShowAlert(true);
              newUser();
            }
          })
          .catch((error) => {
            //se houver erro de autenticação direcionar para o controller LoginComponent
            if (error.response.status === 410 || error.response.status === 401) {
              navigate('/login');
              return;
            }            
            setTypeAlert('alert');
            setTitleAlert('Atenção!');
            setMessageAlert(error.message);
            setShowAlert(true);
          })
          .finally(() => {
            setShowLoading(false);
          });

      // alteração de usuário
      } else {

        // Atualiza registro
        apiService
          .updateUser(formUser)
          .then((result) => {
            
            if (result.status === 200) {
              setTypeAlert('alert');
              setTitleAlert('Sucesso!');
              setMessageAlert('Os dados do usuário foram atualizados.');
              setShowAlert(true);
            }
          })
          .catch((error) => {
            //se houver erro de autenticação direcionar para o controller LoginComponent
            if (error.response.status === 410 || error.response.status === 401) {
              navigate('/login');
              return;
            }            
            setTypeAlert('alert');
            setTitleAlert('Atenção!');
            setMessageAlert(error.message);
            setShowAlert(true);
          })
          .finally(() => {
            setShowLoading(false);
            idRef.current.focus();
            setformUser(initialStateUser);
          });
      }
      userNameRef.current.focus();

    }
  };

  /*
    Pedir confirmação ao clicar no botão delete na
    tabela de pesquisa de usuário. Ao confirmar
    chamar a função userDelete com o id
  */
  const handleClickDelete = () => {
    setTypeAlert('confirmDel');
    setTitleAlert('Atenção!');
    setMessageAlert('Tem certeza que deseja excluir o usuário?');
    setShowAlert(true);
    // O focus foi adicionado nessa função 
    // porque não funciona depois de confirmar e ir para a função
    // delUser
    idRef.current.focus();
  };

  /*
    Mudar o estado do showAlert ao clicar no botão
    cancelar do componente AlertModal
  */
  const handleClickCancel = () => {
    setShowAlert(false);
  }

  /*
    Apagar o registro referente ao id do sócio
    na tabela de pesquisa.
  */
  const handleClickConfirmDelete = (idUser) => {
    setShowUsers(false);
    setShowLoading(true);
    setShowAlert(false);

    apiService
      .delUserById(idUser)
      .then((result) => {
        newUser();
      })
      .catch((error) => {
        //se houver erro de autenticação direcionar para o controller LoginComponent
        if (error.response.status === 410 || error.response.status === 401) {
          navigate('/login');
          return;
        }        
        setTypeAlert('alert');
        setTitleAlert('Atenção!');
        setMessageAlert(error);
        setShowAlert(true);
      })
      .finally(() => {
        setShowLoading(false);
      });

  };


  /*
    Adicionar o valor para o próximo código
  */
  const handleClickNext = () => {
    setShowLoading(true);
    apiService
    .getUserNextCode()
    .then((result) => {
      if (result.status === 200) {

        setformUser(initialStateUser);
        
        setformUser((prevformUser) => ({
          ...prevformUser,
          code: result.data.next_code,
        }));

        userNameRef.current.focus();

      }
    })
    .catch((error) => {
      //se houver erro de autenticação direcionar para o controller LoginComponent
      if (error.response.status === 410 || error.response.status === 401) {
        navigate('/login');
        return;
      }
      setTypeAlert('alert');
      setTitleAlert('Atenção!');
      setMessageAlert(error.message);
      setShowAlert(true);
    })
    .finally(() => {
      setShowLoading(false);
    });
  }

  /*
    Mostrar ou ocultar a senha
  */
  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  /*
    Mostrar os campos de senha
  */
  const handleClickChangePass = () => {
    setShowPassField(true);
    if (passwordRef.current) {
      passwordRef.current.focus();
    }
  }

  return (
    <div className="container mt-3">

      <LoadingSpinner show={showLoading} />

      <AlertModal
        show={showAlert}
        type={typeAlert}
        title={titleAlert}
        message={messageAlert}
        onConfirmDel={() => handleClickConfirmDelete(formUser.id)}
        onOk={handleClickCancel}
        onCancel={handleClickCancel}
      />

      <h3>Usuário</h3>
      <hr></hr>

      <UserModal
        showUsers = {showUsers}
        users = {users}
        handleClickEdit = {handleClickEdit}
        setShowUsers = {setShowUsers}
      ></UserModal>

      <Form onSubmit={handleSubmitUser}>

        <Row>
          <Col xl={12} sm={12} className="mb-3">
            <Form.Group as={Row} controlId="code">
              <Form.Label column xl={2} lg={2} md={3} sm={3}  className="d-flex align-items-center">
                Código:
              </Form.Label>
              <Col xl={2} lg={2} md={3} sm={4}>
                <InputGroup>
                  <Form.Control
                    type="number"
                    placeholder=""
                    name="code"
                    value={formUser.code || ''}
                    onChange={handleChangeUser}
                    onKeyDown={handleKeyDown}
                    onBlur={() => handleClickEdit(formUser.code)}
                    ref={idRef}
                    autoComplete="off"
                    tabIndex="1"
                    autoFocus
                    required
                  />
                  <Button
                    onClick={handleClickNext}
                    variant="outline-secondary"
                    tabIndex="2"
                  >
                    <FaPlus />
                  </Button>                  
                </InputGroup>
              </Col>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col xl={12} sm={12} className="mb-3">
            <Form.Group as={Row} controlId="name">
              <Form.Label column xl={2} lg={2} md={3} sm={3} className="d-flex align-items-center">
                Nome:
              </Form.Label>
              <Col xl={6} lg={7} md={8} sm={9}>
                <InputGroup>
                  <Form.Control
                    type="text"
                    placeholder=""
                    name="name"
                    value={formUser.name || ''}
                    onChange={handleChangeUser}
                    ref={userNameRef}
                    autoComplete="off"
                    tabIndex="3"
                    required
                  />
                  <Button
                    onClick={handleClickSearch}
                    variant="outline-secondary"
                    tabIndex="4"
                  >
                    <FaSearch />
                  </Button>
                </InputGroup>
              </Col>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col xl={12} sm={12} className="mb-3">
            <Form.Group as={Row} controlId="user">
              <Form.Label column xl={2} lg={2} md={3} sm={3} className="d-flex align-items-center">
                Usuário:
              </Form.Label>
              <Col xl={3} lg={3} md={4} sm={5}>
                <Form.Control
                  type="text"
                  placeholder=""
                  name="username"
                  value={formUser.username || ''}
                  onChange={handleChangeUser}
                  autoComplete="new-password"
                  tabIndex="5"
                  required
                />
              </Col>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col xl={12} sm={12} className="mb-3">
            <Form.Group as={Row} controlId="is_admin">
              <Form.Label column xl={2} lg={2} md={3} sm={3} className="d-flex align-items-center">
                Administrador:
              </Form.Label>
              <Col xl={10} lg={10} md={9} sm={9}>
                <Form.Check
                  type="switch"
                  name="is_admin"
                  id="custom-switch"
                  checked={formUser.is_admin}
                  onChange={handleSwitchChange}
                  className="custom-switch"
                  tabIndex="6"
                />
              </Col>
            </Form.Group>
          </Col>
        </Row>

        {showPassField && (
        <>

        <Row>
          <Col xl={12} sm={12} className="mb-3">
            <Form.Group as={Row} controlId="password">
              <Form.Label column xl={2} lg={2} md={3} sm={3} className="d-flex align-items-center">
                Senha:
              </Form.Label>
              <Col xl={3} lg={3} md={4} sm={5}>
                <InputGroup>

                  {showPassword ? (
                    <Form.Control
                    type="text"
                    placeholder=""
                    name="password"
                    ref={passwordRef}
                    value={formUser.password || ''}
                    onChange={handleChangeUser}
                    autoComplete="new-password"
                    tabIndex="7"
                    />
                  ) : (
                    <Form.Control
                      type="password"
                      placeholder=""
                      name="password"
                      ref={passwordRef}
                      value={formUser.password || ''}
                      onChange={handleChangeUser}
                      autoComplete="new-password"
                      tabIndex="7"
                    />                    
                  )}

                  <Button
                    onClick={handleShowPassword}
                    variant="outline-secondary"
                    tabIndex="7"
                  >
                    {showPassword ? (
                      <div>
                        <FaEye />
                      </div>
                    ) : (
                      <div>
                        <FaEyeSlash />
                      </div>
                    )}
                  </Button>   
                </InputGroup>
              </Col>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col xl={12} sm={12} className="mb-3">
            <Form.Group as={Row} controlId="passwordconfirm">
              <Form.Label column xl={2} lg={2} md={3} sm={3} className="d-flex align-items-center">
                Confirme:
              </Form.Label>
              <Col xl={3} lg={3} md={4} sm={5}>
                <InputGroup>

                  {showConfirmPassword ? (
                    <Form.Control
                      type="text"
                      placeholder=""
                      name="passwordconfirm"
                      value={formUser.passwordconfirm || ''}
                      onChange={handleChangeUser}
                      autoComplete="off"
                      tabIndex="8"
                    />
                  ) : (
                    <Form.Control
                      type="password"
                      placeholder=""
                      name="passwordconfirm"
                      value={formUser.passwordconfirm || ''}
                      onChange={handleChangeUser}
                      autoComplete="off"
                      tabIndex="8"
                    />                    
                  )}

                  <Button
                    onClick={handleShowConfirmPassword}
                    variant="outline-secondary"
                    tabIndex="9"
                  >
                    {showConfirmPassword ? (
                      <div>
                        <FaEye />
                      </div>
                    ) : (
                      <div>
                        <FaEyeSlash />
                      </div>
                    )}                    
                  </Button>                  
                </InputGroup>
              </Col>
            </Form.Group>
          </Col>
        </Row> 
        </>
        )}

      

        <Row>
          <hr></hr>
        </Row>
        <Row className="mb-4">
          <Col>

            <Button
              type="submit"
              variant="secondary"
              className="me-2"
              tabIndex="10"
            >
              Gravar
            </Button>

            <Button
              onClick={handleClickNewUser}
              variant="outline-secondary"
              className="me-2"
              tabIndex="11"
            >
              Novo
            </Button>

            {formUser.id && formUser.name && formUser.username &&
              <Button
                onClick={handleClickDelete}
                variant="outline-secondary"
                tabIndex="12"
              >
                Excluir
              </Button>
            }

            {!showPassField && 
              <Button
                onClick={handleClickChangePass}
                variant="outline-secondary"
                className="ms-2"
                tabIndex="12"
              >
                Mudar Senha
              </Button>
            }   


          </Col>
        </Row>

      </Form>

    </div>
  )
}
import React, { useState, useEffect } from 'react';
import { Table, Modal } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';

export default function UserModal({ showUsers, users, handleClickEdit, setShowUsers }) {

  /*
    Paginação
  */
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 5;

  useEffect(() => {
    if (showUsers) {
      setCurrentItems([]);
      setPageCount(0);
      setItemOffset(0);
    }
  }, [showUsers]);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(users.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(users.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, users]);

  const handlePageClick = (event) => {
    const newOffset = event.selected * itemsPerPage % users.length;
    setItemOffset(newOffset);
  };


  return (
    <Modal show={showUsers} onHide={() => setShowUsers(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Usuários</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th>Código</th>
              <th>Nome</th>
              <th>Usuário</th>
            </tr>
          </thead>
          <tbody>
            {currentItems.map((user) => (
              <tr key={user.id} onClick={() => handleClickEdit(user.code)} style={{ cursor: "pointer" }}>
                <td>{user.code}</td>
                <td>{user.name}</td>
                <td>{user.user}</td>
              </tr>
            ))}
          </tbody>
        </Table>
        <ReactPaginate
          nextLabel=">"
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          marginPagesDisplayed={2}
          pageCount={pageCount}
          previousLabel="<"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          containerClassName="pagination"
          activeClassName="active"
          renderOnZeroPageCount={null}
        />
      </Modal.Body>
    </Modal>
  );
}

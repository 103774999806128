import React, { useState, useEffect } from 'react';
import { ListGroup } from 'react-bootstrap';

const AutoSuggest = ({ suggestions, onSelect, inputValue }) => {
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);

  useEffect(() => {
    const filtered = suggestions.filter((suggestion) =>
      suggestion.nome.toLowerCase().includes(inputValue.toLowerCase())
    );
    setFilteredSuggestions(filtered.slice(0, 5)); // Limitar a 5 sugestões
  }, [inputValue, suggestions]);

  return (
    <div className="auto-suggest-container">
      {filteredSuggestions.length > 0 && (
        <ListGroup className="auto-suggest-list">
          {filteredSuggestions.map((suggestion, index) => (
            <ListGroup.Item
              key={index}
              action
              onClick={() => onSelect(suggestion)}
            >
              {suggestion.nome}
            </ListGroup.Item>
          ))}
        </ListGroup>
      )}
    </div>
  );
};

export default AutoSuggest;

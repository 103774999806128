/*
  Função para converter uma string moeda em um
  valor numérico
*/  
export const formatToNum = (valorString) => {
  const valorNumeric = parseFloat(valorString.replace(/[^\d,]/g, '').replace(',', '.'));
  return parseFloat(valorNumeric.toFixed(2));
}

/*
  Função para formatar valor numérico em formato moeda
*/
export const real = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
  decimal: ",",
});

/*
  Função para retornar os nomes dos meses
*/
export const numberToMonth = (monthNumber) => {
  const monthNames = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"];
  return monthNames[monthNumber - 1];
}

/*
  Função para formatar texto em data vinda da API para formato DD-MM-YYYY
*/
export const formatDate = (dateString) => {
  if (!dateString) {
    return ''; // Retorna um valor em branco se o parâmetro for nulo ou ausente
  }

  const date = new Date(dateString);

  // Verifica se a data é válida
  if (isNaN(date)) {
    return ''; // Retorna um valor em branco se a data for inválida
  }

  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
};

/*
  Função para formatar data para o formato aceito pela API YYYY-MM-DD
*/
export const parseDate = (dateString) => {
  const parts = dateString.split('/');
  const formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
  return formattedDate;
};

/*
  Função para verificar se uma string é uma data válida
*/
export const isValidDate = (dateString) => {
  const regEx = /^\d{4}-\d{2}-\d{2}$/;
  if(!dateString.match(regEx)) return false;  // Formato inválido
  const d = new Date(dateString);
  const dNum = d.getTime();
  if(!dNum && dNum !== 0) return false; // NaN value, data inválida
  return d.toISOString().slice(0,10) === dateString;
}
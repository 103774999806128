import React, { useState, useRef } from 'react';
import { Col, Row, Form, InputGroup, Button } from 'react-bootstrap';
import { FaSearch, FaPlus } from 'react-icons/fa';
import AlertModal from '../components/AlertModal';
import LoadingSpinner from '../components/LoadingSpinner';
import apiService from '../services/apiService';
import TableConfederativa from './TableConfederativa';
import ProprietariosModal from './ProprietariosModal';
import { useNavigate } from 'react-router-dom';
import AutoSuggest from '../components/AutoSuggest'; 

export default function ProprietarioForm() {
  // Estado inicial para os campos do formulário.
  const initialStateProprietario = {
    id: '',
    codigo: '',
    nome: '',
    propriedade: '',
    escritorio: '',
    fone: '',
    desde: '',
    obs: '',
  };

  const [formProprietario, setFormProprietario] = useState(initialStateProprietario);

  // Estado inicial para ocultar/mostrar.
  const [showProprietarios, setShowProprietarios] = useState(false);
  const [titleAlert, setTitleAlert] = useState('');
  const [messageAlert, setMessageAlert] = useState('');
  const [typeAlert, setTypeAlert] = useState('alert');
  const [showAlert, setShowAlert] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const navigate = useNavigate();

  // Armazenar os proprietários da pesquisa.
  const [proprietarios, setProprietarios] = useState([]);

  // Sugestões para o campo de nome
  const [suggestions, setSuggestions] = useState([]);

  // Estado para controlar se uma sugestão foi selecionada recentemente
  const [suggestionSelected, setSuggestionSelected] = useState(false);

  /*
    Fazer referência aos campos nome e pesquisa para
    o cursor voltar para eles. Exemplo no input ref={proprietarioNomeRef}
  */
  const proprietarioNomeRef = useRef(null);
  const proprietarioCodRef = useRef(null);

  /*
    Para usar o enter como tab no input de código
  */
  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      const nextElement = document.querySelector('[tabIndex="0"]');
      nextElement.focus();
    }
  };

  /*
    Limpa o formulário e coloca
    o cursor no input nome.
  */
  const novoProprietario = () => {
    setFormProprietario(initialStateProprietario);
    setShowProprietarios(false);
    proprietarioCodRef.current.focus(); // colocar o cursor no campo nome
  };

  const handleClickNovoProprietario = () => {
    novoProprietario();
  }

  /*
    Função para validar os campos do formulário
    no handleSubmitProprietario.
  */
  const validFields = () => {
    if (parseInt(formProprietario.codigo) === 0 ||
      formProprietario.nome.trim() === '' ||
      formProprietario.propriedade.trim() === '') {
      setTypeAlert('alert');
      setTitleAlert('Campos inválidos!');
      setMessageAlert('Preencha pelo menos os campos Código, Nome e Propriedade.');
      setShowAlert(true);
      proprietarioNomeRef.current.focus();
      return false;
    } else {
      return true;
    }
  }

  /*
    Atualiza os dados dos formulários
    quando houver alguma alteração.
  */
  const handleChangeProprietario = (event) => {
    setShowAlert(false);
    const { name, value } = event.target;
    setFormProprietario((prevFormProprietario) => ({
      ...prevFormProprietario,
      [name]: value,
    }));
  };

  /*
    Mostrar o resultado da pesquisa
    por proprietário na tabela
  */
  const handleClickSearch = (event) => {
    event.preventDefault(); // Evitar o recarregamento da página.

    if (formProprietario.nome.trim() !== '') {
      setShowProprietarios(false);
      setShowAlert(false);
      setShowLoading(true);

      apiService
        .getPropByName(formProprietario.nome)
        .then((result) => {
          if (result.status === 200) {
            setProprietarios(result.data);
            setShowProprietarios(true);
            setFormProprietario(initialStateProprietario);
          }
        })
        .catch((error) => {
          setTypeAlert('alert');
          setTitleAlert('Atenção!');

          //se houver erro de autenticação direcionar para o controller LoginComponent
          if (error.response.status === 410 || error.response.status === 401) {
            navigate('/login');
            return;
          } else if (error.response.status === 404) {
            setMessageAlert("Nenhum proprietário foi encontrado.");
            setShowAlert(true);
          } else {
            setMessageAlert(error.message);
            setShowAlert(true);
          }

        })
        .finally(() => {
          proprietarioNomeRef.current.focus();
          setShowLoading(false);
        });
    }
  };

  /*
    Atualização dos dados do formulário vindo através do
    do botão edit da tabela de pesquisa ou quando o campo
    código perder o foco.
  */
  const handleClickEdit = (codigo) => {

    // Condição usada para quando o campo código perder o foco
    if (codigo) {
      setShowProprietarios(false);
      setShowLoading(true);

      apiService
        .getPropByCodigo(codigo)
        .then((result) => {

          if (result.status === 200) {
            const { id, codigo, nome, propriedade, escritorio, fone, desde, obs } = result.data;

            /* se o result.data retornar algum campo com valor nulo
               substituir por '' para evitar erro no código
            */
            setFormProprietario({
              id: id ?? '',
              codigo: codigo ?? '',
              nome: nome ?? '',
              propriedade: propriedade ?? '',
              escritorio: escritorio ?? '',
              fone: fone ?? '',
              desde: desde ?? '',
              obs: obs ?? ''
            });

            proprietarioNomeRef.current.focus();
          }
        })
        .catch((error) => {
          setTypeAlert('alert');
          setTitleAlert('Atenção!');

          //se houver erro de autenticação direcionar para o controller LoginComponent
          if (error.response.status === 410 || error.response.status === 401) {
            navigate('/login');
            return;
          } else if (error.response.status === 404) {
              proprietarioNomeRef.current.focus();
          } else {
            setMessageAlert(error.message);
            setShowAlert(true);
          }

        })
        .finally(() => {
          setShowLoading(false);
        });
    }
  };

  /*
    Enviar os dados do formulário para a api após o submit.
    Se o formProprietário.id estiver vazio será enviado para cadastro,
    senão, será feita uma atualização dos dados.
  */
  const handleSubmitProprietario = (event) => {
    event.preventDefault(); // evitar o recarregamento da página

    if (suggestionSelected) {
      setSuggestionSelected(false); // Redefinir para false após a verificação
      return; // Não submeter o formulário se uma sugestão foi selecionada recentemente
    }

    if (validFields()) {
      setShowLoading(true);

      if (formProprietario.id === '') {
        // Novo registro
        apiService
          .newProp(formProprietario)
          .then((result) => {
            if (result.status === 200) {
              setTypeAlert('alert');
              setTitleAlert('Sucesso!');
              setMessageAlert("Proprietário cadastrado.");
              setShowAlert(true);
              novoProprietario();
            }
          })
          .catch((error) => {

            //se houver erro de autenticação direcionar para o controller LoginComponent
            if (error.response.status === 410 || error.response.status === 401) {
              navigate('/login');
              return;
            }

            setTypeAlert('alert');
            setTitleAlert('Atenção!');
            setMessageAlert(error.response.data.message);
            setShowAlert(true);
          })
          .finally(() => {
            setShowLoading(false);
          });
      } else {
        // Atualiza registro
        apiService
          .updateProp(formProprietario)
          .then((result) => {

            if (result.status === 200) {
              setTypeAlert('alert');
              setTitleAlert('Sucesso!');
              setMessageAlert("Os dados do proprietário foram atualizados.");
              setShowAlert(true);
            }

          })
          .catch((error) => {

            //se houver erro de autenticação direcionar para o controller LoginComponent
            if (error.response.status === 410 || error.response.status === 401) {
              navigate('/login');
              return;
            }
            setTypeAlert('alert');
            setTitleAlert('Atenção!');
            setMessageAlert(error.response.data.message);
            setShowAlert(true);
          })
          .finally(() => {
            setShowLoading(false);
          });
      }
      proprietarioNomeRef.current.focus();

    }
  };

  /*
    Pedir confirmação ao clicar no botão delete na
    tabela de pesquisa de proprietário. Ao confirmar
    chamar a função proprietarioDelete com o id
  */
  const handleClickDelete = () => {
    setTypeAlert('confirmDel');
    setTitleAlert('Atenção!');
    setMessageAlert('Tem certeza que deseja excluir o proprietário?');
    setShowAlert(true);
    // O focus foi adicionado nessa função porque não funciona
    // porque não funciona depois de confirmar e ir para a função
    // delProprietario
    proprietarioNomeRef.current.focus();
  };

  /*
    Mudar o estado do showAlert ao clicar no botão
    cancelar do componente AlertModal
  */
  const handleClickCancel = () => {
    setShowAlert(false);
  }

  /*
    Apagar o registro referente ao id do proprietário
    na tabela de pesquisa.
  */
  const handleClickConfirmDelete = (idProprietario) => {
    setShowProprietarios(false);
    setShowLoading(true);
    setShowAlert(false);

    apiService
      .delPropById(idProprietario)
      .then((result) => {
        novoProprietario();
      })
      .catch((error) => {
        //se houver erro de autenticação direcionar para o controller LoginComponent
        if (error.response.status === 410 || error.response.status === 401) {
          navigate('/login');
          return;
        }
        setTypeAlert('alert');
        setTitleAlert('Atenção!');
        setMessageAlert(error);
        setShowAlert(true);
      })
      .finally(() => {
        setShowLoading(false);
      });

  };

  /*
    Adicionar o valor para o próximo código
  */
  const handleClickNext = () => {
    setShowLoading(true);
    apiService
    .getPropNextCodigo()
    .then((result) => {

      if (result.status === 200) {
        setFormProprietario(initialStateProprietario);

        setFormProprietario((prevFormProprietario) => ({
          ...prevFormProprietario,
          codigo: result.data.proximo_codigo,
        }));

        proprietarioNomeRef.current.focus();
      }
    })
    .catch((error) => {

      //se houver erro de autenticação direcionar para o controller LoginComponent
      if (error.response.status === 410 || error.response.status === 401) {
        navigate('/login');
        return;
      }

      setTypeAlert('alert');
      setTitleAlert('Atenção!');
      setMessageAlert(error.message);
      setShowAlert(true);
    })
    .finally(() => {
      setShowLoading(false);
    });
  }

  // Função para buscar sugestões de nomes
  const fetchSuggestions = async (inputValue) => {
    try {
      const response = await apiService.getPropByName(inputValue);
      setSuggestions(response.data);
    } catch (error) {
      console.error('Erro ao buscar sugestões:', error);
    }
  };

  // Função para selecionar uma sugestão
  const handleSuggestionSelect = (suggestion) => {
    setSuggestionSelected(true); // Definir como true quando uma sugestão for selecionada
    setFormProprietario((prevFormProprietario) => ({
      ...prevFormProprietario,
      id: suggestion.id,
      codigo: suggestion.codigo,
      nome: suggestion.nome,
      propriedade: suggestion.propriedade,
      escritorio: suggestion.escritorio,
      fone: suggestion.fone,
      desde: suggestion.desde,
      obs: suggestion.obs,
    }));
    setSuggestions([]);
  };

  return (
    <div className="container mt-3">
      <LoadingSpinner show={showLoading} />

      <AlertModal
        show={showAlert}
        type={typeAlert}
        title={titleAlert}
        message={messageAlert}
        onConfirmDel={() => handleClickConfirmDelete(formProprietario.id)}
        onOk={handleClickCancel}
        onCancel={handleClickCancel}
      />

      <h3>Proprietário</h3>
      <hr></hr>

      <ProprietariosModal
        showProprietarios={showProprietarios}
        proprietarios={proprietarios}
        handleClickEdit={handleClickEdit}
        setShowProprietarios={setShowProprietarios}
      ></ProprietariosModal>

      <Form onSubmit={handleSubmitProprietario}>
        <Row>
          <Col xl={6} sm={12} className="mb-3">
            <Form.Group as={Row} controlId="codigo">
              <Form.Label column sm={2} className="d-flex align-items-center">
                Código:
              </Form.Label>
              <Col sm={3}>
                <InputGroup>
                  <Form.Control
                    type="number"
                    placeholder=""
                    name="codigo"
                    value={formProprietario.codigo}
                    onChange={handleChangeProprietario}
                    onKeyDown={handleKeyDown}
                    onBlur={() => handleClickEdit(formProprietario.codigo)}
                    ref={proprietarioCodRef}
                    autoComplete="off"
                    tabIndex="1"
                    autoFocus
                    required
                  />
                  <Button
                    onClick={handleClickNext}
                    variant="outline-secondary"
                    tabIndex="2"
                  >
                    <FaPlus />
                  </Button>
                </InputGroup>
              </Col>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col xl={6} sm={12} className="mb-3">
            <Form.Group as={Row} controlId="nome">
              <Form.Label column sm={2} className="d-flex align-items-center">
                Nome:
              </Form.Label>
              <Col sm={10}>
                <InputGroup>
                  <Form.Control
                    type="text"
                    placeholder=""
                    name="nome"
                    value={formProprietario.nome}
                    onChange={(e) => {
                      handleChangeProprietario(e);
                      fetchSuggestions(e.target.value);
                    }}
                    ref={proprietarioNomeRef}
                    autoComplete="off"
                    tabIndex="3"
                    required
                  />
                  <Button
                    onClick={handleClickSearch}
                    variant="outline-secondary"
                    tabIndex="4"
                  >
                    <FaSearch />
                  </Button>
                </InputGroup>
                <AutoSuggest
                  suggestions={suggestions}
                  onSelect={handleSuggestionSelect}
                  inputValue={formProprietario.nome}
                />
              </Col>
            </Form.Group>
          </Col>

          <Col xl={6} sm={12} className="mb-3">
            <Form.Group as={Row} controlId="propriedade">
              <Form.Label column sm={2} className="d-flex align-items-center">
                Propriedade:
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="text"
                  placeholder=""
                  name="propriedade"
                  value={formProprietario.propriedade}
                  onChange={handleChangeProprietario}
                  autoComplete="off"
                  tabIndex="5"
                  required
                />
              </Col>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col xl={6} sm={12} className="mb-3">
            <Form.Group as={Row} controlId="escritorio">
              <Form.Label column sm={2} className="d-flex align-items-center">
                Escritório:
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="text"
                  placeholder=""
                  name="escritorio"
                  value={formProprietario.escritorio}
                  onChange={handleChangeProprietario}
                  autoComplete="off"
                  tabIndex="6"
                />
              </Col>
            </Form.Group>
          </Col>

          <Col xl={6} sm={12} className="mb-3">
            <Form.Group as={Row} controlId="fone">
              <Form.Label column sm={2} className="d-flex align-items-center">
                Fone:
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="text"
                  placeholder=""
                  name="fone"
                  value={formProprietario.fone}
                  onChange={handleChangeProprietario}
                  autoComplete="off"
                  tabIndex="7"
                />
              </Col>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col xl={6} sm={12} className="mb-3">
            <Form.Group as={Row} controlId="obs">
              <Form.Label column sm={2} className="d-flex align-items-center">
                Observação:
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder=""
                  name="obs"
                  value={formProprietario.obs}
                  onChange={handleChangeProprietario}
                  autoComplete="off"
                  tabIndex="8"
                />
              </Col>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <hr></hr>
        </Row>
        <Row className="mb-4">
          <Col>
            <Button
              type="submit"
              variant="secondary"
              className="me-2"
              tabIndex="9"
            >
              Gravar
            </Button>

            <Button
              onClick={handleClickNovoProprietario}
              variant="outline-secondary"
              className="me-2"
              tabIndex="10"
            >
              Novo
            </Button>

            {formProprietario.id && formProprietario.nome &&
              <Button
                onClick={handleClickDelete}
                variant="outline-secondary"
                tabIndex="11"
              >
                Excluir
              </Button>
            }

          </Col>
        </Row>

      </Form>
      {formProprietario.id && formProprietario.nome && (
        <div>
          <br/>
          <h3>Confederativas</h3>

          <TableConfederativa
            proprietarioId={formProprietario.id}
          />
        </div>
      )}
    </div>
  )
}

import { Outlet, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axios from 'axios';

const PrivateRoute = () => {
  const navigate = useNavigate();
  const API_BASE_URL = "https://dbsitrap.dherel.com/api";
  
  const token = localStorage.getItem('sitraptoken');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(true);

  useEffect(() => {
    if (!token) {
      console.log('Token não enviado.');
      setIsAuthenticated(false);
      navigate('/login'); 
      return;
    }

    const config = {
      headers: { Authorization: `Bearer ${token}` } 
    };

    axios.post(`${API_BASE_URL}/verify`, {}, config) 
      .then(response => {
        if (response.data.authorization) {
          setIsAuthenticated(true);
        } else {
          console.log(response);
          setIsAuthenticated(false);
          navigate('/login'); 
        }
      })
      .catch(error => {
        console.log(error);
        setIsAuthenticated(false);
        navigate('/login'); 
      })
      .finally(() => {
        setIsAuthenticating(false);
      });
  }, [token, navigate]);

  // Se o usuário estiver autenticado, renderize o Outlet
  if (isAuthenticating) return null;
  return isAuthenticated && <Outlet />;
};

export default PrivateRoute;
import React from 'react';
import { Spinner } from 'react-bootstrap';

const LoadingSpinner = ({ show }) => {
  return show ? (
    <div
      style={{
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 9999,
        width: '55px',
        height: '55px',
        borderRadius: '50%',
      }}
    >
      <Spinner animation="border" variant="light" />
    </div>
  ) : null;
};

export default LoadingSpinner;
import axios from 'axios';

const API_BASE_URL = "https://dbsitrap.dherel.com/api";

const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  // Outras configurações globais
});


// Interceptor para atualizar o cabeçalho de autorização em todas as requisições
axiosInstance.interceptors.request.use(
  config => {
    const token = localStorage.getItem('sitraptoken');

    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);


const apiService = {
 
  /*
    Autenticação
  */

    async authCheck() {
      const url = `authcheck` ; 
      const response = await axiosInstance.get(url);
      if (response.status === 200) {
        return response;
      }
    },

  /*
    Confederativa
  */

  async newConf(conf) {
    const url = `conf` ; 
    const response = await axiosInstance.post(url, conf);
    if (response.status === 200) {
      return response;
    }
  },


  async getConfByPropId(idProp) {
    const url = `conf/prop/${idProp}` ; 
    const response = await axiosInstance.get(url);
     if (response.status === 200) {
      return response;
    }
  },


  async getConfFirst(idProp) {
    const url = `conf/first/${idProp}` ; 
    const response = await axiosInstance.get(url);
    // Se a resposta do servidor for bem-sucedida retorna a data
    if (response.status === 200) {
      return response.data.mes + '/' + response.data.ano;
    }
  },


  async getConfLast(idProp) {
    const url = `conf/last/${idProp}` ; 
    const response = await axiosInstance.get(url);
    if (response.status === 200) {
      return response.data.mes + '/' + response.data.ano;
    }
  }, 


  async delConfById(idConf) {
    const url = `conf/${idConf}` ; 
    const response = await axiosInstance.delete(url);
    if (response.status === 200) {
      return response;
    }
  },

  /*
    Proprietário
  */

  async newProp(prop) {
    const url = `prop` ; 
    const response = await axiosInstance.post(url, prop);
    if (response.status === 200) {
      return response;
    }
  },


  async getPropByName(nameProp) {
    const url = `prop/nome/${nameProp}` ; 
    const response = await axiosInstance.get(url);
    if (response.status === 200) {
      return response;
    }
  },


  async getPropById(idProp) {
    const url = `prop/${idProp}` ; 
    const response = await axiosInstance.get(url);
    if (response.status === 200) {
      return response;
    }
  },


  async getPropNextCodigo() {
    const url = `nextprop` ; 
    const response = await axiosInstance.get(url);
    if (response.status === 200) {
      return response;
    }
  },


  async getPropByCodigo(codProp) {
    const url = `prop/codigo/${codProp}` ; 
    const response = await axiosInstance.get(url);
    if (response.status === 200) {
      return response;
    }
  },   


  async delPropById(idProp) {
    const url = `prop/${idProp}` ; 
    const response = await axiosInstance.delete(url);
    if (response.status === 200) {
      return response;
    }
  },    


  async updateProp(prop) {
    const url = `prop/${prop.id}` ; 
    const response = await axiosInstance.put(url, prop);
    if (response.status === 200) {
      return response;
    }
  },    

  /*
    Socio
  */

  async newSocio(socio) {
    const url = `socio` ; 
    const response = await axiosInstance.post(url, socio);
    if (response.status === 200) {
      return response;
    }
  },


  async getSocioByName(nameSocio) {
    const url = `socio/nome/${nameSocio}` ; 
    const response = await axiosInstance.get(url);
    if (response.status === 200) {
      return response;
    }
  },


  async getSocioById(idSocio) {
    const url = `socio/${idSocio}` ; 
    const response = await axiosInstance.get(url);
    if (response.status === 200) {
      return response;
    }
  },


  async getSocioNextMatricula() {
    const url = `nextsocio` ; 
    const response = await axiosInstance.get(url);
    if (response.status === 200) {
      return response;
    }
  },


  async getSocioByMatricula(matricula) {
    const url = `socio/matricula/${matricula}` ; 
    const response = await axiosInstance.get(url);
    if (response.status === 200) {
      return response;
    }
  },   


  async delSocioById(idSocio) {
    const url = `socio/${idSocio}` ; 
    const response = await axiosInstance.delete(url);
    if (response.status === 200) {
      return response;
    }
  },    


  async updateSocio(socio) {
    const url = `socio/${socio.id}` ; 
    const response = await axiosInstance.put(url, socio);
    if (response.status === 200) {
      return response;
    }
  },


/*
    Usuário
  */

    async newUser(user) {
      const url = `user` ; 
      const response = await axiosInstance.post(url, user);
      if (response.status === 200) {
        return response;
      }
    },
  

    // verificar se o usuário já existe
    async checkUser(user) {
      const url = `checkuser/${user}` ; 
      const response = await axiosInstance.get(url);
      return response.data === 1 ? true : false;
    },

  
    async getUserByName(nameUser) {
      const url = `user/name/${nameUser}` ; 
      const response = await axiosInstance.get(url);
      if (response.status === 200) {
        return response;
      }
    },
  
  
    async getUserById(idUser) {
      const url = `user/${idUser}` ; 
      const response = await axiosInstance.get(url);
      if (response.status === 200) {
        return response;
      }
    },


    async getUserByCode(codeUser) {
      const url = `user/code/${codeUser}` ; 
      const response = await axiosInstance.get(url);
      if (response.status === 200) {
        return response;
      }
    },

    
    async getUserNextCode() {
      const url = `nextuser` ; 
      const response = await axiosInstance.get(url);
      if (response.status === 200) {
        return response;
      } else {
        console.log(response.status);
      }
    },


    async delUserById(idUser) {
      const url = `user/${idUser}` ; 
      const response = await axiosInstance.delete(url);
      if (response.status === 200) {
        return response;
      }
    },
  
  
    async updateUser(user) {
      const url = `user/${user.id}`; 
      const response = await axiosInstance.put(url, user);
      if (response.status === 200) {
        return response;
      }
    },


    async getAllUser(user) {
      const url = `user`; 
      const response = await axiosInstance.get(url);
      if (response.status === 200) {
        return response;
      }
    },


};

export default apiService;
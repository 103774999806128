import React from 'react';
import Form from 'react-bootstrap/Form';

const MonthSelect = ({ value, onChange, tabIndex }) => {
  const meses = [
    { valor: '01', nome: 'Janeiro' },
    { valor: '02', nome: 'Fevereiro' },
    { valor: '03', nome: 'Março' },
    { valor: '04', nome: 'Abril' },
    { valor: '05', nome: 'Maio' },
    { valor: '06', nome: 'Junho' },
    { valor: '07', nome: 'Julho' },
    { valor: '08', nome: 'Agosto' },
    { valor: '09', nome: 'Setembro' },
    { valor: '10', nome: 'Outubro' },
    { valor: '11', nome: 'Novembro' },
    { valor: '12', nome: 'Dezembro' },
  ];

  return (
    <Form.Control
      as="select"
      name="mes"
      value={value}
      onChange={onChange}
      tabIndex={tabIndex}
      required
    >
      <option value="">Selecione</option>
      {meses.map((mes) => (
        <option key={mes.valor} value={mes.valor}>
          {mes.nome}
        </option>
      ))}
    </Form.Control>
  );
}

export default MonthSelect;
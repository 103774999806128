import React, { useState, useRef, useEffect } from 'react';
import { Modal, Col, Row, Form, InputGroup, Button, Alert } from 'react-bootstrap';
import LoadingSpinner from './LoadingSpinner';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import logo from '../assets/logo.png'; // Importar a imagem

export default function LoginComponent({ setIsLoggedIn }) {
  const [credentials, setCredentials] = useState({ username: '', password: '' });
  const [showLoading, setShowLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [modalVisible, setModalVisible] = useState(true);
  const userRef = useRef(null);
  const passwordRef = useRef(null); // Referência para o campo de senha
  const API_BASE_URL = "https://dbsitrap.dherel.com/api";
  const location = useLocation();
  const navigate = useNavigate();
  

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCredentials((prevCredentials) => ({
      ...prevCredentials,
      [name]: value,
    }));
  };

  useEffect(() => {
    localStorage.removeItem('sitraptoken');
  }, []);

  useEffect(() => {
    if (message) {
      const timer = setTimeout(() => {
        setMessage('');
        //userRef.current.focus();
      }, 5000); // 5 segundos
      return () => clearTimeout(timer);
    }
  }, [message]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    userRef.current.focus();

    setShowLoading(true);

    if (!credentials.username || !credentials.password) {
      setMessage('Por favor, preencha ambos os campos.');
      setShowLoading(false);
      return;
    }

    try {
      const response = await axios.post(`${API_BASE_URL}/login`, credentials);

      localStorage.setItem('sitraptoken', response.data.token);

      setModalVisible(false);
      setIsLoggedIn(true); // Define o estado de login como verdadeiro
      navigate('/proprietario'); // Redireciona para a rota /proprietarios após o login bem-sucedido
    } catch (error) {
      console.error('Erro ao fazer login:', error);

      if (error.response && error.response.status === 401) {
        setMessage('Por favor, verifique suas credenciais e tente novamente.');
      } else if (error.response && error.response.status === 400) {
        setMessage('Usuário ou senha inválidos.');
      } else {
        setMessage('Houve um problema ao acessar os dados do servidor.');
      }
    } finally {
      setShowLoading(false);
    }
  };

  // Função para ocultar outros elementos quando a rota for /login
  const hideOtherElements = location.pathname === '/login';

  return (
    <div>
      {hideOtherElements && (
        <style jsx>{`
          body {
            background-image: none;
            background-color: #f8f9fa;
          }
          .wrapper {
            display: none;
          }
        `}</style>
      )}

      <LoadingSpinner show={showLoading} />

      <Modal show={modalVisible} centered className="login-modal">
        
        <div className="text-center login-logo">
          <img src={logo} alt="Logo" />
        </div>

        <Modal.Body>
          <Form onSubmit={handleSubmit} className="login-form">
            <Row>
              <Col className="mb-3">
                <Form.Group as={Row} controlId="user">
                  <Form.Label column sm={2} className="d-flex align-items-center">
                    Usuário:
                  </Form.Label>
                  <Col sm={10}>
                    <InputGroup>
                      <Form.Control
                        type="text"
                        placeholder=""
                        name="username"
                        value={credentials.username}
                        onChange={handleChange}
                        required
                        autoComplete="off"
                        tabIndex="1"
                        ref={userRef}
                      />
                    </InputGroup>
                  </Col>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col className="mb-3">
                <Form.Group as={Row} controlId="password">
                  <Form.Label column sm={2} className="d-flex align-items-center">
                    Senha:
                  </Form.Label>
                  <Col sm={10}>
                    <InputGroup>
                      <Form.Control
                        type="password"
                        placeholder=""
                        name="password"
                        value={credentials.password}
                        onChange={handleChange}
                        required
                        autoComplete="off"
                        tabIndex="2"
                        ref={passwordRef} // Atribui a referência para o campo de senha
                      />
                    </InputGroup>
                  </Col>
                </Form.Group>
              </Col>
            </Row>

            <Row className="mt-2">
              <Col>
                <Button
                  type="submit"
                  variant="secondary"
                  className="me-2 d-grid w-100"
                  tabIndex="3"
                >
                  Entrar
                </Button>
              </Col>
            </Row>

            <Row className="mt-2">
              <Col>
                {message && <Alert variant="danger" className="text-center">{message}</Alert>}
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
}

import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
import Proprietario from './proprietario/ProprietarioForm';
import Socio from './socio/SocioForm';
import User from './user/UserForm';
import LoginComponent from './components/LoginComponent';
import PrivateRoute from './components/PrivateRoute';

function App() {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 767) {
        setIsCollapsed(true);
      } else {
        setIsCollapsed(false);
      }
    };

    // Chama a função inicialmente para definir o estado inicial
    handleResize();

    // Adiciona um event listener para ajustar a sidebar quando a janela for redimensionada
    window.addEventListener('resize', handleResize);

    // Remove o event listener ao desmontar o componente
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const token = localStorage.getItem('sitraptoken');
    if (token) {
      setIsLoggedIn(true);
    }
  }, []);

  return (
    <BrowserRouter>
      <div className="wrapper">
        {/* Sidebar */}
        <aside id="sidebar" className={isCollapsed ? 'collapsed' : ''}>
          <div className="h-100">
            <div className="sidebar-logo">
              <Link to="/"></Link>
            </div>
            <ul className="sidebar-nav">
              <li className="sidebar-item">
                <Link to="/socio" className="sidebar-link">
                  <i className="fa-solid fa-list pe-2"></i>
                  Associado
                </Link>
              </li>
              <li className="sidebar-item">
                <Link to="/proprietario" className="sidebar-link">
                  <i className="fa-solid fa-user pe-2"></i>
                  Proprietário
                </Link>
              </li>
              <li className="sidebar-item">
                <Link to="/user" className="sidebar-link">
                  <i className="fa-solid fa-users pe-2"></i>
                  Usuário
                </Link>
              </li>
            </ul>
          </div>
        </aside>

        <div className="main">
          <nav className="navbar navbar-expand px-3 d-flex justify-content-between">
            <button
              className="btn"
              type="button"
              onClick={toggleSidebar}
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            <div>
              <Link to="/login">
                <button
                  className="btn"
                  type="button"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-power" viewBox="0 0 16 16">
                    <path d="M7.5 1v7h1V1z"/>
                    <path d="M3 8.812a5 5 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812"/>
                  </svg>
                </button>
              </Link>
            </div>
          </nav>

          {/* Content */}
          <main className="content px-3 py-2">
            <div className="container-fluid">
              <Routes>
                <Route path="/login" element={<LoginComponent setIsLoggedIn={setIsLoggedIn} />} />
                <Route element={<PrivateRoute />}>
                  <Route path="/" element={<Proprietario />} />
                  <Route path="/socio" element={<Socio />} />
                  <Route path="/proprietario" element={<Proprietario />} />
                  <Route path="/user" element={<User />} />
                </Route>
              </Routes>
            </div>
          </main>
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;

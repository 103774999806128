import React, { useState, useRef } from 'react';
import { Col, Row, Form, InputGroup, Button } from 'react-bootstrap';
import { FaSearch, FaPlus } from 'react-icons/fa';
import AlertModal from '../components/AlertModal';
import LoadingSpinner from '../components/LoadingSpinner';
import apiService from '../services/apiService';
import SociosModal from './SociosModal';
import { useNavigate } from 'react-router-dom';

//import {formatDate, parseDate} from '../components/utils';

export default function SocioForm() {

  // Estado inical para os campos do formulário.
  const initialStateSocio = {
    id: '',
    matricula: '',
    socio_nome: '',
    socio_nascimento: '',
    conjuge_nome: '',
    conjuge_nascimento: '',
  };

  const [formSocio, setFormSocio] = useState(initialStateSocio);

  // Estado inicial para ocultar/mostrar. 
  const [showSocios, setShowSocios] = useState(false);
  const [titleAlert, setTitleAlert] = useState('');
  const [messageAlert, setMessageAlert] = useState('');
  const [typeAlert, setTypeAlert] = useState('alert');
  const [showAlert, setShowAlert] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const navigate = useNavigate();

  // Armazenar os socios da pesquisa.
  const [socios, setSocios] = useState([]);

  const socioNomeRef = useRef(null);
  const matriculaRef = useRef(null);

  /*
    Para usar o enter como tab no input de código
  */
  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      const nextElement = document.querySelector('[tabIndex="0"]');
      nextElement.focus();
    }
  };

  /*
    Limpa o formulário e coloca
    o cursor no input nome.
  */
  const novoSocio = () => {
    setFormSocio(initialStateSocio);
    setShowSocios(false);
    matriculaRef.current.focus(); // colocar o cursor no campo nome
  };

  const handleClickNovoSocio = () => {
    novoSocio();
  }

  /*
    Função para validar os campos do formulário
    no handleSubmitSocio.
  */
  const validFields = () => {
    const dataAtual = new Date();

    if (formSocio.socio_nascimento !== null) {
      const dataNascSocio = new Date(formSocio.socio_nascimento);
      
      if (formSocio.socio_nascimento.length > 10 || dataNascSocio >= dataAtual) {
        setTypeAlert('alert');
        setTitleAlert('Data Inválida!');
        setMessageAlert('A data de nascimento do sócio não é válida.');
        setShowAlert(true);
        return false;
      }

    }

    if (formSocio.conjuge_nascimento !== null) {
      const dataNascConjuge = new Date(formSocio.conjuge_nascimento);
  
      if (formSocio.conjuge_nascimento.length > 10 || dataNascConjuge >= dataAtual) {
        setTypeAlert('alert');
        setTitleAlert('Data Inválida!');
        setMessageAlert('A data de nascimento do conjuge não é válida.');
        setShowAlert(true);
        return false;
      }
      
    }

    if (
      parseInt(formSocio.matricula) === 0 ||
      formSocio.socio_nome.trim() === '' ||
      formSocio.socio_nascimento === ''
      ) {
      setTypeAlert('alert');
      setTitleAlert('Campos inválidos!');
      setMessageAlert('Preencha pelo menos os campos matrícula, nome e nascimento do sócio.');
      setShowAlert(true);
      socioNomeRef.current.focus();
      return false;
    } else {
      return true;
    }

  }

  /*
    Atualiza os dados dos formulários
    quando houver alguma alteração.
  */
  const handleChangeSocio = (event) => {
    setShowAlert(false);
    const { name, value } = event.target;
    setFormSocio((prevFormSocio) => ({
      ...prevFormSocio,
      [name]: value,
    }));
  };

  /*
    Mostar o resultado da pesquisa
    por sócio na tabela
  */
  const handleClickSearch = (event) => {
    event.preventDefault(); // Evitar o recarregamento da página.

    if (formSocio.socio_nome.trim() !== '') {
      setShowSocios(false);
      setShowAlert(false);
      setShowLoading(true);

      apiService
        .getSocioByName(formSocio.socio_nome)
        .then((result) => {
          if (result.status === 200) {
            setSocios(result.data);
            setShowSocios(true);
            setFormSocio(initialStateSocio);
          }
        })
        .catch((error) => {
          setTypeAlert('alert');
          setTitleAlert('Atenção!');
          //se houver erro de autenticação direcionar para o controller LoginComponent
          if (error.response.status === 410 || error.response.status === 401) {
            navigate('/login');
            return;
          } else if (error.response.status === 404) {
            setMessageAlert("Nenhum sócio foi encontrado.");
            setShowAlert(true);
          } else {
            setMessageAlert(error.message);
            setShowAlert(true);
          }
        })
        .finally(() => {
          socioNomeRef.current.focus();
          setShowLoading(false);
        });
    }
  };

  /*
    Atualização dos dados do formulário vindo através do
    do botão edit da tabela de pesquisa ou quando o campo
    código perder o foco.
  */
  const handleClickEdit = (matricula) => {
    
    // Condição usada para quando o campo matrícula perder o foco
    if (matricula) {
      setShowSocios(false);
      setShowLoading(true);

      apiService
        .getSocioByMatricula(matricula)
        .then((result) => {
          
          if (result.status === 200) {
            const { id, matricula, socio_nome, socio_nascimento, conjuge_nome, conjuge_nascimento} = result.data;

            /* se o result.data retornar algum campo com valor nulo
               substituir por '' para evitar erro no código
            */
            setFormSocio({
              id: id ?? '',
              matricula: matricula ?? '',
              socio_nome: socio_nome ?? '',
              socio_nascimento: socio_nascimento ?? '',
              conjuge_nome: conjuge_nome ?? '',
              conjuge_nascimento: conjuge_nascimento ?? ''
            });

            socioNomeRef.current.focus();
          }
        })
        .catch((error) => {
          setTypeAlert('alert');
          setTitleAlert('Atenção!');
          //se houver erro de autenticação direcionar para o controller LoginComponent
          if (error.response.status === 410 || error.response.status === 401) {
            navigate('/login');
            return;
          } else if (error.response.status === 404) {
              socioNomeRef.current.focus();
          } else {
            setMessageAlert(error.message);
            setShowAlert(true);
          }
        })
        .finally(() => {
          setShowLoading(false);
        });
    }
  };

  /*
    Enviar os dados do formulário para a api após o submit.
    Se o formSocio.id estiver vazio será enviado para cadastro,
    senão, será feita uma atualização dos dados. 
  */
  const handleSubmitSocio = (event) => {
    event.preventDefault(); // evitar o recarregamento da página

    if (validFields()) {
      setShowLoading(true);

      if (formSocio.id === '') {

        /* Apagar conjuge_nascimento para não dar erro quando ele
           não for preenchido. Foi criado uma cópia do formSocio
           para não usar o delete diretamente no formSocio
           e usar setFormSocio da maneira correta.
        */
        const updatedFormSocio = { ...formSocio };
        
        if (formSocio.conjuge_nascimento === '') {
          delete updatedFormSocio.conjuge_nascimento;
        }

        setFormSocio(updatedFormSocio);

        apiService
          .newSocio(formSocio)
          .then((result) => {
            
            if (result.status === 200) {
              setTypeAlert('alert');
              setTitleAlert('Sucesso!');
              setMessageAlert("Sócio cadastrado.");
              setShowAlert(true);
              novoSocio();
            }

          })
          .catch((error) => {

            //se houver erro de autenticação direcionar para o controller LoginComponent
            if (error.response.status === 410 || error.response.status === 401) {
              navigate('/login');
              return;
            }

            setTypeAlert('alert');
            setTitleAlert('Atenção!');
            setMessageAlert(error.message);
            setShowAlert(true);
          })
          .finally(() => {
            setShowLoading(false);
          });
      } else {
        // Atualiza registro
        apiService
          .updateSocio(formSocio)
          .then((result) => {

            if (result.status === 200) {
              setTypeAlert('alert');
              setTitleAlert('Sucesso!');
              setMessageAlert("Os dados do sócio foram atualizados.");
              setShowAlert(true);
            }

          })
          .catch((error) => {

            //se houver erro de autenticação direcionar para o controller LoginComponent
            if (error.response.status === 410 || error.response.status === 401) {
              navigate('/login');
              return;
            }

            setTypeAlert('alert');
            setTitleAlert('Atenção!');
            setMessageAlert(error.message);
            setShowAlert(true);
          })
          .finally(() => {
            setShowLoading(false);
            matriculaRef.current.focus();
            setFormSocio(initialStateSocio);
          });
      }
      socioNomeRef.current.focus();

    }
  };

  /*
    Pedir confirmação ao clicar no botão delete na
    tabela de pesquisa de sócio. Ao confirmar
    chamar a função socioDelete com o id
  */
  const handleClickDelete = () => {
    setTypeAlert('confirmDel');
    setTitleAlert('Atenção!');
    setMessageAlert('Tem certeza que deseja excluir o sócio?');
    setShowAlert(true);
    // O focus foi adicionado nessa função 
    // porque não funciona depois de confirmar e ir para a função
    // delSocio
    matriculaRef.current.focus();
  };

  /*
    Mudar o estado do showAlert ao clicar no botão
    cancelar do componente AlertModal
  */
  const handleClickCancel = () => {
    setShowAlert(false);
  }

  /*
    Apagar o registro referente ao id do sócio
    na tabela de pesquisa.
  */
  const handleClickConfirmDelete = (idSocio) => {
    setShowSocios(false);
    setShowLoading(true);
    setShowAlert(false);

    apiService
      .delSocioById(idSocio)
      .then((result) => {
        novoSocio();
      })
      .catch((error) => {

        //se houver erro de autenticação direcionar para o controller LoginComponent
        if (error.response.status === 410 || error.response.status === 401) {
          navigate('/login');
          return;
        }

        setTypeAlert('alert');
        setTitleAlert('Atenção!');
        setMessageAlert(error);
        setShowAlert(true);
      })
      .finally(() => {
        setShowLoading(false);
      });

  };


  /*
    Adicionar o valor para o próximo código
  */
  const handleClickNext = () => {
    setShowLoading(true);
    apiService
    .getSocioNextMatricula()
    .then((result) => {
      if (result.status === 200) {

        setFormSocio(initialStateSocio);
        
        setFormSocio((prevFormSocio) => ({
          ...prevFormSocio,
          matricula: result.data.proxima_matricula,
        }));

        socioNomeRef.current.focus();

      }
    })
    .catch((error) => {

      //se houver erro de autenticação direcionar para o controller LoginComponent
      if (error.response.status === 410 || error.response.status === 401) {
        navigate('/login');
        return;
      }

      setTypeAlert('alert');
      setTitleAlert('Atenção!');
      setMessageAlert(error.message);
      setShowAlert(true);
    })
    .finally(() => {
      setShowLoading(false);
    });
  }


  return (
    <div className="container mt-3">

      <LoadingSpinner show={showLoading} />

      <AlertModal
        show={showAlert}
        type={typeAlert}
        title={titleAlert}
        message={messageAlert}
        onConfirmDel={() => handleClickConfirmDelete(formSocio.id)}
        onOk={handleClickCancel}
        onCancel={handleClickCancel}
      />

      <h3>Assossiado</h3>
      <hr></hr>

      <SociosModal
        showSocios = {showSocios}
        socios = {socios}
        handleClickEdit = {handleClickEdit}
        setShowSocios = {setShowSocios}
      ></SociosModal>

      <Form onSubmit={handleSubmitSocio}>

        <Row>
          <Col xl={7} lg={6} md={12} sm={12} className="mb-3">
            <Form.Group as={Row} controlId="matricula">
              <Form.Label column xl={3} lg={3} md={3} sm={3} className="d-flex align-items-center">
                Matrícula:
              </Form.Label>
              <Col xl={3} lg={4} md={3} sm={4}>
                <InputGroup>
                  <Form.Control
                    type="number"
                    placeholder=""
                    name="matricula"
                    value={formSocio.matricula}
                    onChange={handleChangeSocio}
                    onKeyDown={handleKeyDown}
                    onBlur={() => handleClickEdit(formSocio.matricula)}
                    ref={matriculaRef}
                    autoComplete="off"
                    tabIndex="1"
                    autoFocus
                    required
                  />
                  <Button
                    onClick={handleClickNext}
                    variant="outline-secondary"
                    tabIndex="2"
                  >
                    <FaPlus />
                  </Button>                  
                </InputGroup>
              </Col>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col xl={7} lg={6} md={12} sm={12} className="mb-3">
            <Form.Group as={Row} controlId="socio_nome">
              <Form.Label column xl={3} lg={3} md={3} sm={3} className="d-flex align-items-center">
                Nome:
              </Form.Label>
              <Col xl={9} lg={9} md={9} sm={9}>
                <InputGroup>
                  <Form.Control
                    type="text"
                    placeholder=""
                    name="socio_nome"
                    value={formSocio.socio_nome}
                    onChange={handleChangeSocio}
                    ref={socioNomeRef}
                    autoComplete="off"
                    tabIndex="3"
                    required
                  />
                  <Button
                    onClick={handleClickSearch}
                    variant="outline-secondary"
                    tabIndex="4"
                  >
                    <FaSearch />
                  </Button>
                </InputGroup>
              </Col>
            </Form.Group>
          </Col>

          <Col xl={5} lg={6} md={12} sm={12} className="mb-3">
            <Form.Group as={Row} controlId="socio_nascimento">
              <Form.Label column xl={3} lg={3} md={3} sm={3} className="d-flex align-items-center">
                Data Nasc.:
              </Form.Label>
              <Col xl={4} lg={4} md={4} sm={4}>
                <Form.Control
                  type="date"
                  placeholder=""
                  name="socio_nascimento"
                  value={formSocio.socio_nascimento}
                  onChange={handleChangeSocio}
                  autoComplete="off"
                  tabIndex="5"
                  required
                />
              </Col>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col xl={7} lg={6} md={12} sm={12} className="mb-3">
            <Form.Group as={Row} controlId="conjuge_nome">
              <Form.Label column xl={3} lg={3} md={3} sm={3} className="d-flex align-items-center">
                Conjuge:
              </Form.Label>
              <Col xl={9} lg={9} md={9} sm={9}>
                <InputGroup>
                  <Form.Control
                    type="text"
                    placeholder=""
                    name="conjuge_nome"
                    value={formSocio.conjuge_nome}
                    onChange={handleChangeSocio}
                    autoComplete="off"
                    tabIndex="6" 
                  />
                </InputGroup>
              </Col>
            </Form.Group>
          </Col>

          <Col xl={5} lg={6} md={12} sm={12} className="mb-3">
            <Form.Group as={Row} controlId="conjuge_nascimento">
              <Form.Label column xl={3} lg={3} md={3} sm={3} className="d-flex align-items-center">
                Data Nasc.:
              </Form.Label>
              <Col xl={4} lg={4} md={4} sm={4}>
                <Form.Control
                  type="date"
                  placeholder=""
                  name="conjuge_nascimento"
                  value={formSocio.conjuge_nascimento}
                  onChange={handleChangeSocio}
                  autoComplete="off"
                  tabIndex="7"
                />
              </Col>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <hr></hr>
        </Row>
        <Row className="mb-4">
          <Col>

            <Button
              type="submit"
              variant="secondary"
              className="me-2"
              tabIndex="8"
            >
              Gravar
            </Button>

            <Button
              onClick={handleClickNovoSocio}
              variant="outline-secondary"
              className="me-2"
              tabIndex="9"
            >
              Novo
            </Button>

            {formSocio.id && formSocio.socio_nome && formSocio.socio_nascimento &&
              <Button
                onClick={handleClickDelete}
                variant="outline-secondary"
                tabIndex="10"
              >
                Excluir
              </Button>
            }

          </Col>
        </Row>

      </Form>
    </div>
  )
}
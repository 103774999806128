import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const AlertModal = ({ show, type, title, message, onOk, onConfirm, onConfirmDel, onCancel }) => {
  
  const handleClose = () => {
    onCancel();
  }

  const handleOk = () => {
    onOk();
  }

  const handleConfirm = () => {
    onConfirm();
  }

  const handleConfirmDel = () => {
    onConfirmDel();
  };

  return (
    <Modal show={show}>
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{message}</Modal.Body>
      <Modal.Footer>
        {type === 'alert' && (
          <Button variant="secondary" autoFocus onClick={handleOk}>
            Ok
          </Button>
        )}

        {type === 'confirm' && (
          <>
            <Button variant="outline-secondary" onClick={handleClose}>
              Cancelar
            </Button>
            <Button variant="secondary" autoFocus onClick={handleConfirm}>
              Confirmar
            </Button>
          </>
        )}

        {type === 'confirmDel' && (
          <>
            <Button variant="outline-secondary" autoFocus onClick={handleClose}>
              Cancelar
            </Button>
            <Button variant="danger" onClick={handleConfirmDel}>
              Confirmar
            </Button>
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default AlertModal;
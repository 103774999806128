import React, { useState, useEffect, useCallback } from 'react';
import { Button, Table, Form } from 'react-bootstrap';
import { FaTrashAlt, FaPlusSquare } from 'react-icons/fa';
import { NumericFormat, PatternFormat } from 'react-number-format';
import LoadingSpinner from '../components/LoadingSpinner';
import AlertModal from '../components/AlertModal';
import apiService from '../services/apiService';
import MonthSelect from '../components/MonthSelect';
import { formatToNum, real, numberToMonth } from '../components/utils';
import ReactPaginate from 'react-paginate';

const TableConfederativa = ({ proprietarioId }) => {

  const initialStateConfederativa = {
    id: '',
    mes: '',
    ano: '',
    valor: '',
    proprietario_id: ''
  };

  const [confederativaFormData, setConfederativaFormData] = useState(initialStateConfederativa);

  // Armazenar as confederativas do proprietário.
  const [confederativas, setConfederativas] = useState([]);

  // Estado inicial para ocultar/mostrar. 
  const [typeAlert, setTypeAlert] = useState('alert');
  const [titleAlert, setTitleAlert] = useState('');
  const [messageAlert, setMessageAlert] = useState('');
  const [showAlert, setShowAlert] = useState(false);

  
  const [showLoading, setShowLoading] = useState(false);
  const [confederativaId, setConfederativaId] = useState('');

  /*
    Preencher os dados das confederativas do proprietário
    assim que o componente for carregado
  */
  useEffect(() => {
    getConfs(proprietarioId);
  }, [proprietarioId]);


  /*
    Pega as confederativas referente ao Id do proprietário
  */
  const getConfs = (propId) => {
    setShowLoading(true);
    apiService
      .getConfByPropId(propId)
      .then((result) => {
        if (result.status === 200) {
          setConfederativas(result.data);
        }
      })
      .catch((error) => {
        if (error.response && error.response.status !== 404) {
          setTypeAlert('alert');
          setTitleAlert('Atenção!');
          setMessageAlert(error.message);
          setShowAlert(true);
        }
      })
      .finally(() => {
        setShowLoading(false);

      });
  }

  const handleChangeConfederativa = (event) => {
    setShowAlert(false);
    const { name, value } = event.target;
    setConfederativaFormData((prevFormConfederativa) => ({
      ...prevFormConfederativa,
      [name]: value,
    }));
  };


  const handleClickDelete = useCallback((id) => {
    setConfederativaId(id);
    setTypeAlert('confirmDel');
    setTitleAlert('Atenção!');
    setMessageAlert('Tem certeza que deseja excluir?');
    setShowAlert(true);
  }, []);


  const handleClickCancel = useCallback(() => {
    setShowAlert(false);
  }, []);


  const handleClickConfirmDelete = useCallback(() => {
    setShowAlert(false);
    setShowLoading(true);
    apiService
      .delConfById(confederativaId)
      .then((result) => {
        if (result.status === 200) {
          getConfs(proprietarioId);
        }
      })
      .catch((error) => {
        setTypeAlert('alert');
        setTitleAlert('Atenção!');
        setMessageAlert(error.message);
        setShowAlert(true);
      })
      .finally(() => {
        setShowLoading(false);
      });

  }, [confederativaId, proprietarioId]);

  /*
    Função para validar os campos do formulário
    no handleSubmitConfederativa.
  */
  const validFields = () => {
    if (!confederativaFormData.mes.toString().trim() ||
      !confederativaFormData.ano.toString().trim() ||
      !confederativaFormData.valor.toString().trim()) {
      setTitleAlert('Atenção!');
      setTypeAlert('alert');
      setMessageAlert('Preencha os campos mês, ano e valor.');
      setShowAlert(true);
      return false;
    } else {
      return true;
    }
  }

  /*
    Enviar os dados do formulário
    para a api após o submit.
  */
  const handleSubmitConfederativa = (event) => {
    event.preventDefault(); // evitar o recarregamento da página
    setShowAlert(false);

    if (validFields()) {
      setShowLoading(true);
      setTypeAlert('alert');
      setTitleAlert('Atenção!');

      // Tratar o dado do campo valor para que fique no formato numérico
      let valorConfederativa = formatToNum(confederativaFormData.valor);
      confederativaFormData.valor = valorConfederativa;

      // Preenche o valor do id do proprietário
      confederativaFormData.proprietario_id = proprietarioId;

      apiService
        .newConf(confederativaFormData)
        .then((result) => {
          if (result.status !== 200) {
            setMessageAlert(result);
            setShowAlert(true);
            setShowLoading(false);
          }
        })
        .catch((error) => {
          setMessageAlert(error.message);
          setShowAlert(true);
          setShowLoading(false);
        })
        .finally(() => {
          setShowLoading(false);
          getConfs(proprietarioId);
          setConfederativaFormData(initialStateConfederativa);
        });

    }
  };


  /*
    Paginação
  */
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 5;

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(confederativas.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(confederativas.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, confederativas]);

  const handlePageClick = (event) => {
    const newOffset = event.selected * itemsPerPage % confederativas.length;
    setItemOffset(newOffset);
  };


  return (
    <div>

      <LoadingSpinner show={showLoading} />

      <AlertModal
        show={showAlert}
        type={typeAlert}
        title={titleAlert}
        message={messageAlert}
        onConfirmDel={handleClickConfirmDelete}
        onOk={handleClickCancel}
        onCancel={handleClickCancel}
      />

      <Form onSubmit={handleSubmitConfederativa}>
        <Table striped bordered hover responsive>

          <thead>
            <tr>
              <th>Mês</th>
              <th style={{ width: '80px', textAlign: 'center' }}>Ano</th>
              <th style={{ width: '150px', textAlign: 'center' }}>Valor</th>
              <th style={{ width: '50px', textAlign: 'center' }}>Ação</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <MonthSelect
                  value={confederativaFormData.mes}
                  onChange={handleChangeConfederativa}
                  tabIndex="12"
                />
              </td>
              <td className='text-center'>
                <PatternFormat
                  customInput={Form.Control}
                  name="ano"
                  value={confederativaFormData.ano}
                  onChange={handleChangeConfederativa}
                  format="####"
                  tabIndex="13"
                  required
                />
              </td>
              <td style={{ width: '150px', textAlign: 'right' }}>
                <NumericFormat
                  customInput={Form.Control}
                  name="valor"
                  prefix="R$ "
                  placeholder="R$ 0,00"
                  thousandSeparator="."
                  decimalSeparator=","
                  value={confederativaFormData.valor}
                  onChange={handleChangeConfederativa}
                  decimalScale={2}
                  tabIndex="14"
                  required
                />
              </td>
              <td style={{ width: '50px', textAlign: 'center' }}>
                <Button
                  type="submit"
                  size="sm"
                  variant="secondary"
                  className="pt-0 me-2 text-center"
                  tabIndex="15"
                >
                  <FaPlusSquare />
                </Button>
              </td>
            </tr>

            {currentItems.map((confederativa) => (
              <tr key={confederativa.id}>
                <td>{numberToMonth(confederativa.mes)}</td>
                <td className='text-center'>{confederativa.ano}</td>
                <td style={{ width: '150px', textAlign: 'right' }}>{real.format(confederativa.valor)}</td>
                <td style={{ width: '50px', textAlign: 'center' }}>
                  <Button
                    variant="secondary"
                    size="sm"
                    className="pt-0 me-2 text-center"
                    onClick={() => handleClickDelete(confederativa.id)}
                    tabIndex="16"
                  >
                    <FaTrashAlt />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Form>

      <ReactPaginate
        nextLabel=">"
        onPageChange={handlePageClick}
        pageRangeDisplayed={3}
        marginPagesDisplayed={2}
        pageCount={pageCount}
        previousLabel="<"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakLabel="..."
        breakClassName="page-item"
        breakLinkClassName="page-link"
        containerClassName="pagination"
        activeClassName="active"
        renderOnZeroPageCount={null}
      />

    </div>
  );
}

export default TableConfederativa;